





































































































import Vue from "vue";
import InputForm from "@/interfaces/InputForm";

export default Vue.extend({
  name: "InputData",
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      form: {
        fullRollWidth: 1.9,
        fullRollLength: 200,
        halfRollWidth: 0.95,
        halfRollLength: 100,
        requiredOverlap: 50,
        // jobWidth: 6, // REMOVE ME
        // jobLength: 2000, // REMOVE ME
      } as InputForm,
    };
  },
  methods: {
    validate(e: any) {
      e?.preventDefault();
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.submitForm();
      }
    },
    submitForm() {
      this.$emit("form-submitted", {
        jobWidth: parseFloat(this.form.jobWidth.toString()),
        jobLength: parseFloat(this.form.jobLength.toString()),
        fullRollWidth: parseFloat(this.form.fullRollWidth.toString()),
        fullRollLength: parseFloat(this.form.fullRollLength.toString()),
        halfRollWidth: parseFloat(this.form.halfRollWidth.toString()),
        halfRollLength: parseFloat(this.form.halfRollLength.toString()),
        requiredOverlap: parseFloat(this.form.requiredOverlap.toString()),
      });
    },
  },
});
