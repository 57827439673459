


























export default {
  props: {
    readyToPrint: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    print(): void {
      window.print();
    },
  },
};
