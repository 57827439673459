
































import Vue, { PropType } from "vue";
import InputForm from "@/interfaces/InputForm";
export default Vue.extend({
  name: "InputDataMini",
  props: {
    formData: {
      type: Object as PropType<InputForm>,
    },
  },
  methods: {
    edit() {
      this.$emit("edit-form");
    },
  },
});
