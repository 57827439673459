import InputForm from "@/interfaces/InputForm";
import Job from "@/interfaces/Job";
import Run, { RunParams } from "@/interfaces/Run";

export default function calculateCoverage(data: InputForm): Promise<Job> {
  const runs: Run[] = [];
  let remainingWidth = data.jobWidth;
  let startingLength = 0;
  let runNumber = 1;

  // Lay down the majority of full rolls
  while (remainingWidth >= data.fullRollWidth) {
    const runParams: RunParams = {
      runNumber: runNumber,
      startingLength: startingLength,
      rollWidth: data.fullRollWidth,
      rollLength: data.fullRollLength,
      runLength: data.jobLength,
      requiredOverlap: data.requiredOverlap,
      remainingWidth: remainingWidth,
    };

    // Calculate the run
    const run = calculateRun(runParams);

    // Push run to the array
    runs.push(run);

    // Reset some variables for next run
    runNumber += 1;
    startingLength = run.remainingLength;
    remainingWidth -= run.rollWidth;
    remainingWidth += run.requiredOverlap * 0.001;
  }

  const alreadyFilled =
    runs.length > 0 && remainingWidth <= data.requiredOverlap * 0.001;

  // Use the half roll for last run
  if (
    remainingWidth <= data.halfRollWidth &&
    remainingWidth > 0 &&
    !alreadyFilled
  ) {
    startingLength = 0; // We have to start with a new type of roll

    const runParams: RunParams = {
      runNumber: runNumber,
      startingLength: startingLength,
      rollWidth: data.halfRollWidth,
      rollLength: data.halfRollLength,
      runLength: data.jobLength,
      requiredOverlap: data.requiredOverlap,
      remainingWidth: remainingWidth,
    };

    // Calculate the run
    const run = calculateRun(runParams);

    // Push run to the array
    runs.push(run);
  }
  // Use the full roll for last run
  else if (remainingWidth > 0 && !alreadyFilled) {
    const runParams: RunParams = {
      runNumber: runNumber,
      startingLength: startingLength,
      rollWidth: data.fullRollWidth,
      rollLength: data.fullRollLength,
      runLength: data.jobLength,
      requiredOverlap: data.requiredOverlap,
      remainingWidth: remainingWidth,
    };

    // Calculate the run
    const run = calculateRun(runParams);

    // Push run to the array
    runs.push(run);
  }

  // Work out total rolls
  let totalRolls = 0;
  let totalFullRolls = 0;
  let totalHalfRolls = 0;
  for (const run of runs) {
    totalRolls += run.rollsStarted;
    if (run.rollWidth === data.fullRollWidth)
      totalFullRolls += run.rollsStarted;
    if (run.rollWidth === data.halfRollWidth)
      totalHalfRolls += run.rollsStarted;
  }
  // TO-DO!

  // Work out overlap area
  let totalRollArea = 0;
  for (const run of runs) {
    totalRollArea +=
      run.rollWidth * run.configuration.reduce((a, b) => a + b, 0);
  }
  const overlapArea = totalRollArea - data.jobLength * data.jobWidth;

  // Work out percentage wastage
  const wastagePercentage = (overlapArea / totalRollArea) * 100;

  const job: Job = {
    totalRolls: totalRolls,
    totalFullRolls: totalFullRolls,
    totalHalfRolls: totalHalfRolls,
    length: data.jobLength,
    width: data.jobWidth,
    estimatedArea: data.jobLength * data.jobWidth,
    overlapArea: overlapArea,
    wastagePercentage: wastagePercentage,
    runs: runs,
  };

  return Promise.resolve(job);
}

function calculateRun(params: RunParams): Run {
  const config: number[] = [];
  if (params.startingLength) {
    config.push(Math.min(params.startingLength, params.runLength));
  }
  let remainingLength = params.runLength - params.startingLength;
  let rollsStarted = 0;

  // Fit full rolls
  while (remainingLength >= params.rollLength) {
    config.push(params.rollLength);
    rollsStarted += 1;
    remainingLength -= params.rollLength;
    remainingLength += params.requiredOverlap * 0.001;
  }

  // Use roll that will be cut short
  if (remainingLength > 0) {
    rollsStarted += 1;
    config.push(remainingLength);
  }

  return {
    runNumber: params.runNumber,
    startingLength: Math.min(params.startingLength, params.runLength),
    remainingLength: params.rollLength - remainingLength,
    rollsStarted: rollsStarted,
    configuration: config,
    rollLength: params.rollLength,
    rollWidth: params.rollWidth,
    runLength: params.runLength,
    requiredOverlap: params.requiredOverlap,
    remainingWidth: params.remainingWidth,
  } as Run;
}
