<template>
  <div>
    <v-divider class="my-3"></v-divider>
    <h6>Copyright Dom Barter | {{ new Date().getFullYear() }}</h6>
    <h6>v1.0.1</h6>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
