



























import Vue from "vue";
import InputData from "@/components/InputData.vue";
import InputDataMini from "@/components/InputDataMini.vue";
import PageTitle from "@/components/PageTitle.vue";
import CopyrightNotice from "@/components/CopyrightNotice.vue";
import CalculationResults from "@/components/CalculationResults.vue";
import InputForm from "@/interfaces/InputForm";
import Job from "@/interfaces/Job";
import CalculationStage from "@/enums/CalculationStage";
import calculateCoverage from "@/services/coverage-calculator";

export default Vue.extend({
  name: "Home",
  components: {
    InputData,
    PageTitle,
    CopyrightNotice,
    CalculationResults,
    InputDataMini,
  },
  data() {
    return {
      currentStage: CalculationStage.WaitingForForm,
      results: {} as Job,
      formData: {} as InputForm,
    };
  },
  methods: {
    formSubmitted(data: InputForm): void {
      this.currentStage = CalculationStage.LoadingResults;
      this.formData = data;
      calculateCoverage(data).then((result) => {
        this.results = result;
        setTimeout(() => {
          this.currentStage = CalculationStage.ResultsReady;
        }, 1300);
      });
    },
    editRequested(): void {
      this.currentStage = CalculationStage.WaitingForForm;
    },
  },
  computed: {
    isLoading(): boolean {
      return this.currentStage === CalculationStage.LoadingResults;
    },
    resultsReady(): boolean {
      return this.currentStage === CalculationStage.ResultsReady;
    },
  },
});
