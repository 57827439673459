





















































































import Vue, { PropType } from "vue";
import InputForm from "@/interfaces/InputForm";
import Job from "@/interfaces/Job";
import Run from "@/interfaces/Run";

export default Vue.extend({
  name: "CalculationResults",
  props: {
    results: {
      type: Object as PropType<Job>,
      required: true,
    },
    formData: {
      type: Object as PropType<InputForm>,
      required: true,
    },
  },
  methods: {
    getPillColour(item: Run): string {
      return item.rollWidth === this.formData.fullRollWidth
        ? "primary"
        : "blue lighten-3";
    },
    getRollClass(run: Run, roll: number, index: number): string {
      const base = "me-1 my-1 ";

      const isStartingRoll = run.startingLength === roll && index === 0;
      const isEndingRoll =
        index === run.configuration.length - 1 && roll < run.rollLength;

      if (isStartingRoll && isEndingRoll) {
        return base + "rounded-0";
      } else if (isStartingRoll) {
        return base + "rounded-r-xl rounded-0";
      } else if (isEndingRoll) {
        return base + "rounded-l-xl rounded-0";
      } else {
        return base;
      }
    },
    getKey(v: number): string {
      return (v * Math.random()).toString();
    },
    formatRollSize(roll: number): string {
      if (roll.toString() === Math.round(roll).toString()) {
        return roll.toString();
      } else {
        return roll.toFixed(2);
      }
    },
    getOverlap(index: number, remainingWidth: number, runs: Run[]): number {
      let overlap;
      // Only 1 roll and it's bigger than or equal to job
      if (index == 0 && runs.length === 1) {
        overlap = runs[0].rollWidth - this.formData.jobWidth;
        // Standard first roll
      } else if (index === 0) {
        overlap = 0;
        // Standard middle rolls
      } else if (index < runs.length - 1) {
        overlap = this.formData.requiredOverlap * 0.001;
        // Final roll
      } else {
        overlap =
          runs[runs.length - 1].rollWidth -
          remainingWidth +
          this.formData.requiredOverlap * 0.001;
      }

      return parseFloat((overlap * 1000).toFixed(2));
    },
  },
});
